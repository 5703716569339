import React, { FC } from 'react'
import { ContentBlockProps } from '@/types/site.interface'
import RichContent from '../molecules/rich-content'
import Section from '../molecules/section'

const ContentBlock: FC<ContentBlockProps> = ({ layout, textAlign, content, image, section }) => {
  return (
    <Section section={section}>
      <RichContent content={content} image={image} layout={layout} textAlign={textAlign} />
    </Section>
  )
}

export default ContentBlock
