import React, { FC } from 'react'
import cx from 'clsx'
import BlockContent from '@/components/blocks/block-content'
import { Blocks, ContentBlockLayout, ImageAsset, TextAlign } from '@/types/site.interface'
import Grid from '@ignition/library/components/atoms/grid'
import ImageElement from '../elements/image'

interface ContentBlockProps {
  content: Blocks
  textAlign?: TextAlign
  layout?: ContentBlockLayout
  image?: ImageAsset
}

const styles = {
  grid: (layout: ContentBlockLayout) =>
    cx({ '!flex flex-col-reverse': layout === 'image-bottom' }, 'my-8 sm:gap-4 lg:gap-8 grid-flow-row-dense'),
  blockContent: (textAlign: TextAlign) =>
    cx(
      textAlign === 'left' && 'sm:text-left',
      textAlign === 'right' && 'sm:text-right',
      'text-center mb-10 mx-auto max-w-[80ch] prose m-w-prose text-xl text-gray-500',
    ),
  image: 'block relative mx-auto prose max-w-prose h-full rounded-lg overflow-hidden',
}

const RichContent: FC<ContentBlockProps> = ({ layout = 'text-only', textAlign = 'left', content, image }) => {
  const withImage = layout !== 'text-only' && !!image?.asset
  const isSingleColumnLayout = !withImage || layout === 'image-top'
  const isReversed = withImage && (layout === 'image-right' || layout === 'image-right-text-wide')
  const wideLayout = layout === 'image-right-text-wide' || layout === 'image-left-text-wide'
  const gridLg = isSingleColumnLayout ? 1 : wideLayout ? 3 : 2
  return (
    <Grid columns={{ base: 1, lg: gridLg }} className={styles.grid(layout)}>
      {withImage && (
        <Grid.Item colspan={{ base: 1 }} colStart={isReversed && { lg: gridLg }}>
          <figure className="w-full relative mx-auto prose max-w-prose">
            <ImageElement className={styles.image} image={image} fill />
            {image.copyright && <figcaption className="mt-2 flex text-sm text-gray-500">{image.copyright}</figcaption>}
          </figure>
        </Grid.Item>
      )}
      <Grid.Item colspan={{ base: wideLayout ? 2 : 1 }} colStart={isReversed && { lg: 1 }}>
        <BlockContent className={styles.blockContent(textAlign)} blocks={content} />
      </Grid.Item>
    </Grid>
  )
}

export default RichContent
